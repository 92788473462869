const AppConfigDefs = {
    app: {
        abreviatura: "SIC",
        nome: "Sistema de Inteligência Comercial",
        empresa: "Efficax Tecnologia",
        footer: "Efficax Tecnologia - 2022"
    },
    login: {
        logo: {
            ligth: 'layout/efficax/images/logo-light.png',
            dark: 'layout/efficax/images/logo-dark.png',
            style: 'height: 60px;'
        },
        logoname: {
            ligth: 'layout/efficax/images/logo-nome-light.png',
            dark: 'layout/efficax/images/logo-nome-dark.png',
            style: 'width: 180px;'
        }
    },
    topbar: {
        logo: {
            ligth: 'layout/efficax/images/logo-light.png',
            dark: 'layout/efficax/images/logo-dark.png',
            style: 'height:40px;'
        },
        logoname: {
            ligth: 'layout/efficax/images/logo-nome-light.png',
            dark: 'layout/efficax/images/logo-nome-dark.png',
            style: 'height:40px;'
        }
    },
    sifm: true
};

export default AppConfigDefs;